import AwsS3 from 'aws-sdk/clients/s3';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Sync from './Sync';
import { Persistent, Version } from '../../../../controllers';
import Confirm from './Confirm';
import { cardActions } from '../../../../store/_actions';
import config from '../../../../config';

const bucket = new AwsS3({
  region: 'eu-central-1',
  credentials: {
    accessKeyId: 'AKIAIQO73IQU52FEOQAA',
    secretAccessKey: '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',
  },
  params: {
    Bucket: 'lingvolab-be',
  },
});

const STZ_KEY = Persistent.constant.SYNC_TZ_ITEM_KEY;
const LTZ_KEY = Persistent.constant.LOCAL_TZ_ITEM_KEY;
const VERSION_KEY = Persistent.constant.VERSION_ITEM_KEY;

let remoteFile = null;
let fileName = null;

function SyncContainrFunc({ localStore, dispatch, userId }) {
  const [confirmTZs, setConfirmTZs] = useState(null);
  const [openNote, setOpenNote] = useState(null);
  const [sync, setSync] = useState(false);

  function getRemoteStz() {
    return remoteFile ? remoteFile.Metadata[STZ_KEY] : null;
  }

  async function download() {
    try {
      remoteFile = await bucket.getObject({
        Key: fileName,
      }).promise();
    } catch (er) {
      console.log(er);
    }
  }

  const syncStoreUp = async () => {
    const Body = JSON.stringify(localStore);

    const ltz = Persistent.read(LTZ_KEY);

    try {
      await bucket.putObject({
        Key: fileName,
        Body,
        Metadata: {
          [STZ_KEY]: ltz,
          [VERSION_KEY]: `${Version.constant.CURRENT_VERSION}`,
        },
      }).promise();

      Persistent.write(STZ_KEY, ltz);

      setOpenNote('database was uploaded in cloud');
      setConfirmTZs(null);
      setSync(false);
    } catch (re) {
      console.log(re);

      setOpenNote('error on uploading in cloud');
      setConfirmTZs(null);
      setSync(false);
    }
  };

  function syncStoreDown() {
    const remoteStore = JSON.parse(remoteFile.Body.toString('utf-8'));

    const remoteVersion = remoteFile.Metadata[VERSION_KEY] || '0';
    const remoteStoreMigrated = Version.migrateFromVersion(
      parseInt(remoteVersion, 10),
      remoteStore,
    );

    dispatch(cardActions.set(remoteStoreMigrated));

    setOpenNote('database was downloaded from cloud');
    setConfirmTZs(null);
    setSync(false);

    Persistent.write(STZ_KEY, getRemoteStz());
  }

  function confirm(ltz, rtz) {
    setConfirmTZs(
      {
        ltz,
        rtz,
      },
    );
  }

  const doSync = async () => {
    console.log('run doSync');
    setSync(true);
    await download();

    const remoteStz = getRemoteStz();
    const localStz = Persistent.read(STZ_KEY);
    const ltz = Persistent.read(LTZ_KEY);

    if (!remoteStz) {
      await syncStoreUp();
    } else if (!localStz) {
      confirm(ltz, remoteStz);
    } else if (remoteStz === localStz) {
      if (ltz > localStz) {
        await syncStoreUp();
      } else {
        setOpenNote('remote & local version are synchronised');
        setSync(false);
      }
    } else {
      confirm(ltz, remoteStz);
    }
  };

  function confirmCloseHandle(upOrDown) {
    if (upOrDown === 'up') {
      syncStoreUp();
    } else {
      syncStoreDown();
    }
  }

  useEffect(() => {
    if (userId) {
      fileName = `${userId}.json`;
      doSync();
    }
  // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    let to;

    console.log('in effect');

    function privateSetTimeout() {
      console.log('in set to', to);

      if (to) {
        clearTimeout(to);
      }

      to = setTimeout(() => {
        doSync();
      }, config.SYNC_AFTER_IDLE_IN_SECS * 1000);
    }

    document.addEventListener('click', privateSetTimeout);

    return () => {
      clearTimeout(to);
      document.removeEventListener('click', privateSetTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Sync rotate={sync} onClick={doSync} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openNote}
        autoHideDuration={3000}
        onClose={() => setOpenNote(null)}
        message={openNote}
      />
      {!!confirmTZs && (
        <Confirm
          ltz={confirmTZs.ltz}
          rtz={confirmTZs.rtz}
          onClose={confirmCloseHandle}
          open={!!confirmTZs}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  localStore: state,
  userId: state.user,
});

export default connect(mapStateToProps)(SyncContainrFunc);

import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import SideForm from './SideForm';
import { cardConstants } from '../../store/_constants';
import { cardActions } from '../../store/_actions';

const styles = (theme) => {
  const doubleSpace = `${theme.spacing.unit * 2}px`;
  const space = `${theme.spacing.unit}px`;

  const margin = [space, doubleSpace, space, doubleSpace].join(' ');

  return {
    textField: {
      margin,
    },

    headline: {
      margin,
    },

    standardMargin: {
      margin,
    },

    form: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'absolute',
      width: '100%',
    },
  };
};

class CardEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { card } = this.props;

    return {
      stage: cardConstants.CARD_EDIT_STAGE_FRONT,
      front: card ? card.front : '',
      back: card ? card.back : '',
    };
  };

  handleClick = (value) => {
    if (this.state.stage === cardConstants.CARD_EDIT_STAGE_FRONT) {
      this.setState({
        ...this.state,
        stage: cardConstants.CARD_EDIT_STAGE_BACK,
        front: value,
      });
    } else if (this.props.card) {
      this.props.dispatch(cardActions.edit(
        this.props.card,
        this.state.front,
        value,
      ));
      this.props.onClose();
    } else {
      this.props.dispatch(cardActions.add(
        this.state.front,
        value,
      ));
      this.setState(this.getInitialState());
    }
  };

  render() {
    return (
      <div>
        {this.state.stage === cardConstants.CARD_EDIT_STAGE_FRONT &&
        <SideForm
          onClick={this.handleClick}
          header="front side"
          buttonCaption="forward"
          value={this.state.front}
        />}
        {this.state.stage === cardConstants.CARD_EDIT_STAGE_BACK &&
        <SideForm
          onClick={this.handleClick}
          header="back side"
          buttonCaption="save"
          value={this.state.back}
        />}
      </div>
    );
  }
}

export default connect()(withStyles(styles)(CardEditForm));

import React from 'react';
import { Button, Typography, withStyles } from '@material-ui/core';
import MyInput from './MyInput';
import margin from './styles/margin';

const styles = theme => ({
  form: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  standardMargin: {
    margin: margin(theme),
  },
});

class SideForm extends React.Component {
  state = {
    value: this.props.value,
  };

  buttonDisabled = () => !this.state.value.trim();

  handleClick = () => {
    this.props.onClick(this.state.value);
  };

  handleChange = (value) => {
    this.setState({
      value,
    });
  };

  render() {
    return (
      <form className={this.props.classes.form}>
        <Typography
          className={this.props.classes.standardMargin}
          type="body2"
        >
          {this.props.header}
        </Typography>
        <MyInput
          onChange={this.handleChange}
          value={this.state.value}
        />
        <Button
          className={this.props.classes.standardMargin}
          variant="contained"
          color="primary"
          disabled={this.buttonDisabled()}
          onClick={this.handleClick}
        >
          {this.props.buttonCaption}
        </Button>
      </form>);
  }
}

export default withStyles(styles)(SideForm);

import React from "react";
import {AppBar, Drawer, withStyles} from "@material-ui/core";
import Menu from "./Menu";
import App from "./App";
import {connect} from "react-redux";
import {pageActions} from "../store/_actions";
import {pageConstants} from "../store/_constants";
import MyToolbar from './App/MyToolbar/MyToolbar';
import {MemoryRouter as Router} from "react-router-dom";

const styles = (theme) => {
  return {
    app: {
      position: 'fixed',
      top: theme.mixins.toolbar.minHeight,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: 'auto',
      background: '#e6e6e6',
      [theme.breakpoints.up('sm')]: {
          top: 64,
      }
    }
  };
};

class AppContainer extends React.Component {

  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  handleClickLogin = () => {
    this.props.dispatch(
      pageActions.set(pageConstants.PAGE_LOGIN)
    );
  };

  render() {
    return (
      <Router>
        <div className={this.props.classes.root}>
          <AppBar position="fixed">
            <MyToolbar user={this.props.user} handleClickLogin={this.handleClickLogin} handleClick={this.handleClick}/>
          </AppBar>
          <Drawer open={this.state.open} onClose={this.handleClose}>
            <Menu handleClose={this.handleClose}/>
          </Drawer>
          <div className={this.props.classes.app}>
            <App/>
          </div>
        </div>
      </Router>
    );
  }
}

const mappingStateToProp = state => ({
  user: state.user
});


export default connect(mappingStateToProp)(withStyles(styles)(AppContainer));
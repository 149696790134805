import React from 'react';
import { Paper, Typography } from '@material-ui/core';

function MyCard({ classes, text }) {
  return (
    <Paper className={`${classes.paper} ${classes.flip}`}>
      <div className={classes.word}>
        <Typography style={{
          fontSize: '100%',
          padding: '1em',
        }}
        >
          {text}
        </Typography>
      </div>
    </Paper>
  );
}

export default MyCard;

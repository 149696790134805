import { cardConstants } from '../_constants';

function add(front, back) {
  return {
    type: cardConstants.CARD_ADD,
    front,
    back,
  };
}

function reset() {
  return {
    type: cardConstants.CARD_RESET,
  };
}

function reschedule() {
  return {
    type: cardConstants.CARD_RESCHEDULE,
  };
}

function edit(card, front, back) {
  return {
    type: cardConstants.CARD_EDIT,
    card,
    front,
    back,
  };
}

function del(card) {
  return {
    type: cardConstants.CARD_DEL,
    card,
  };
}

function shed(card, result) {
  return {
    type: cardConstants.CARD_SHED,
    card,
    result,
  };
}

function setStore(store) {
  return {
    type: cardConstants.STORE_SET,
    store,
  };
}

function setReviewed(card) {
  return {
    type: cardConstants.CARD_SET_REVIEWED,
    card,
  };
}

function shedCardsForToday(cardsCount) {
  return {
    type: cardConstants.SHED_CARDS_FOR_TODAY,
    cardsCount,
  };
}

const cardActions = {
  add,
  reset,
  reschedule,
  edit,
  shed,
  del,
  setReviewed,
  set: setStore,
  shedCardsForToday,
};

export { cardActions as default, cardActions };

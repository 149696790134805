import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import Learn from './Learn/Learn';
import { pageConstants } from '../../../store/_constants';
import { cardActions } from '../../../store/_actions';
import DialogAddCards from './DialogAddCards/DialogAddCards';

function getRandomItem(items) {
  return items[Math.floor(Math.random() * items.length)];
}

function LearnContainer({ cards, dispatch }) {
  const today = moment().format('YYYYMMDD');

  const [currentCard, setCurrentCard] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const [findCard, setFindCard] = useState(false);

  const toggleFindCard = () => {
    setFindCard(!findCard);
  };

  const cardStacks = {
    toRepeat: [],
    toReview: [],
    toLearn: [],
  };

  Object.values(cards).forEach((card) => {
    if (card.scheduled) {
      if (card.review) {
        cardStacks.toReview.push(card);
      } else if (card.scheduled <= today) {
        cardStacks.toRepeat.push(card);
      }
    } else {
      cardStacks.toLearn.push(card);
    }
  });

  /* eslint-disable  react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cardStacks.toRepeat.length) {
      setCurrentCard(getRandomItem(cardStacks.toRepeat));
    } else if (cardStacks.toReview.length) {
      setCurrentCard(getRandomItem(cardStacks.toReview));
    } else if (cardStacks.toLearn.length) {
      setShowDialog(true);
      //      setCurrentCard(getRandomItem(cardStacks.toLearn));
    } else {
      setCurrentCard(null);
    }
  }, [findCard]);

  if (currentCard === null) {
    return (<Redirect to={pageConstants.PAGE_ADD} />);
  }

  return (
    <>
      {currentCard && (
      <Learn
        nextCard={toggleFindCard}
        card={currentCard}
        currentCardScheduledOn={cards[currentCard.id].scheduled}
        queueCounters={{
          toRepeat: cardStacks.toRepeat.length,
          toReview: cardStacks.toReview.length,
          toLearn: cardStacks.toLearn.length,
        }}
        handleShed={(result) => {
          dispatch(cardActions.shed(
            currentCard,
            result,
          ));
        }}
        handleReview={(result) => {
          if (result) {
            dispatch(cardActions.setReviewed(currentCard));
          }
        }}
      />
      )}
      <DialogAddCards
        open={showDialog}
        onClose={() => setShowDialog(false)}
        findNextCard={toggleFindCard}
        maxCards={cardStacks.toLearn.length}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ cards: state[state.curCollection].cards });

export default connect(mapStateToProps)(LearnContainer);

import shortId from 'shortid';
import keyBy from 'lodash.keyby';

const constant = {
  CURRENT_VERSION: 4,
};

const Migration = {
  // add index
  1: {
    up: (store) => ({
      ...store,
      cards: store.cards.map((card) => ({
        ...card,
        id: shortId.generate(),
      })),
    }),

    down: (store) => store,
  },

  // cards to object
  2: {
    up: (store) => ({
      ...store,
      cards: keyBy(store.cards, 'id'),
    }),

    down: (store) => ({
      ...store,
      cards: Object.values(store.cards),
    }),
  },

  // cards to object
  3: {
    up: (store) => {
      const ret = {
        ...store,
        default: {
          cards: store.cards,
        },
        curCollection: 'default',
      };

      delete ret.cards;

      return ret;
    },

    down: (store) => {
      const ret = {
        ...store,
        cards: store.default.cards,
      };

      delete ret.default;
      delete ret.curCollection;

      return ret;
    },
  },
  // user to userId
  4: {
    up: (store) => {
      const ret = {
        ...store,
        user: store.user ? store.user.Eea : null,
      };

      return ret;
    },

    down: (store) => {
      const ret = {
        ...store,
        user: store.user ? {
          Eea: store.user,
        } : null,
      };

      return ret;
    },
  },
};

const Version = {
  constant,

  migrateFromVersion: (versionNr, store) => {
    let retStore = {
      ...store,
    };
    let i;

    if (constant.CURRENT_VERSION > versionNr) {
      for (i = versionNr; i < constant.CURRENT_VERSION; i += 1) {
        retStore = Migration[i + 1].up(retStore);
      }
    } else if (constant.CURRENT_VERSION < versionNr) {
      for (i = versionNr; i > constant.CURRENT_VERSION; i -= 1) {
        retStore = Migration[i].down(retStore);
      }
    }

    return retStore;
  },
};

export { Version as default, Version };

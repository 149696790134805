import React from 'react';
import {Input, Paper, withStyles} from "@material-ui/core";
import margin from "./styles/margin";

const styles = (theme) => {
  return {
    paper: {
      flexGrow: 1,
      height: 10,
      overflowY: 'auto',
      margin: margin(theme),
      position: 'relative'
    },

    myMultiline: {
      height: '100%',
      width: '100%',
      padding: theme.spacing.unit
    },

    myInputMultiline: {
      height: '100%',
    }
  };
};

class MyInput extends React.Component {

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Input
          classes={{
            multiline: this.props.classes.myMultiline,
            inputMultiline: this.props.classes.myInputMultiline
          }}
          autoFocus
          onChange={this.handleChange}
          multiline
          rows={5}
          value={this.props.value}
        />
      </Paper>
    );
  };
}

export default withStyles(styles)(MyInput);
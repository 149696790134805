import { colConstants } from '../_constants';

function add(name) {
  return {
    type: colConstants.COL_ADD,
    name,
  };
}

function set(name) {
  return {
    type: colConstants.COL_SET,
    name,
  };
}

const colActions = {
  add,
  set,
};

export { colActions as default, colActions };

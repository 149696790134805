import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { cardActions } from '../../../../store/_actions';

const cardsCounts = [5, 10, 25, 50];
const noneValue = 'none';

function DialogAddCards({
  open, onClose, maxCards, dispatch, findNextCard,
}) {
  const countsToShow = cardsCounts.filter((c) => c < maxCards);
  countsToShow.unshift(noneValue);
  countsToShow.push(maxCards);

  const [value, setValue] = useState(noneValue);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Typography>
          You are ready with learn process today.
        </Typography>
        <FormControl component="fieldset">
          <FormLabel component="legend">How many new cards do you want to add to you learn process?</FormLabel>
          <RadioGroup
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          >
            {countsToShow.map(
              (c) => (
                <FormControlLabel
                  key={c}
                  value={c.toString()}
                  control={<Radio />}
                  label={c}
                />
              ),
            )}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            if (value !== noneValue) {
              await dispatch(cardActions.shedCardsForToday(+value));
              findNextCard();
            }
            onClose();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect()(DialogAddCards);

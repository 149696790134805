import { Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import Link from 'react-router-dom/es/Link';
import { pageConstants } from '../../../store/_constants';
import SyncContainer from './Sync/SyncContainer';

const MyToolbar = ({ handleClick, user }) => (
  <Toolbar disableGutters>
    <IconButton
      onClick={handleClick}
      color="inherit"
      aria-label="Menu"
    >
      <MenuIcon />
    </IconButton>
    <Typography
      type="title"
      color="inherit"
      style={{ flex: 1 }}
    >
      Lingvo Lab
    </Typography>
    {!user &&
    <Button
      component={Link}
      to={pageConstants.PAGE_LOGIN}
      color="inherit"
    >
      Login
    </Button>
    }
    {user &&
    <SyncContainer user={user} />
    }
  </Toolbar>
);

export default MyToolbar;

import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ListItem, List, ListItemText, ListItemSecondaryAction,
  IconButton, Typography, withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
} from '@material-ui/core';
import config from '../../../../config';

const styles = () => ({
  dayAsText: {
    flex: 1,
  },

  dayHeader: {
    width: '100%',
    display: 'flex',
  },
});

class Day extends Component {
  state = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.setState({
      ...this.state,
      expanded: !this.state.expanded,
    });
  };

  render() {
    const {
      day, cards, handleClickOnCard, handleDeleteClick,
    } = this.props;
    const { dayHeader, dayAsText } = this.props.classes;

    return (
      <div>
        <ExpansionPanel expanded={this.state.false} onChange={this.toggleExpanded}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={dayHeader} variant="overline">
              <div className={dayAsText}>
                {day !== 'undefined' && moment(day).format(config.DATE_FORMAT_SHORT)}
                {day === 'undefined' && 'not yet scheduled'}
              </div>
              <div>({cards.length})</div>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List dense style={{
              width: '100%'
            }}>
              {cards.map(card => (
                <LazyLoad
                  overflow
                  height={54}
                  key={card.id}
                >
                  <ListItem
                    onClick={handleClickOnCard(card)}
                  >
                    <ListItemText
                      primary={card.front}
                      secondary={card.back}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        edge="end"
                        onClick={handleDeleteClick(card)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </LazyLoad>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(Day);

import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import marginFunc from './styles/margin';
import { userActions } from '../../store/_actions';

const styles = (theme) => {
  const margin = marginFunc(theme);

  return {
    wrapper: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },

    centered: {
      margin,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: !!props.user,
    };
  }

  componentDidMount() {
    const gapiScript = document.createElement('script');
    gapiScript.src = 'https://apis.google.com/js/api.js?onload=onGapiLoad';
    window.onGapiLoad = () => {
      window.gapi.load('auth2:signin2', {
        callback: () => {
          window.gapi.auth2.init({
            client_id: '1071075408718-pqdc3fiojvga16b0kvf1d5lqhrd54cla.apps.googleusercontent.com',
          }).then(() => {
            // eslint-disable-next-line react/destructuring-assignment
            if (!this.state.signedIn) {
              this.renderSignInButton();
            }
          });
        },
      });
    };
    document.body.appendChild(gapiScript);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setSignItState(!!nextProps.user);
  }

  componentDidUpdate() {
    const { signedIn } = this.state;

    if (!signedIn) {
      this.renderSignInButton();
    }
  }

  onSuccess = (googleUser) => {
    const { dispatch } = this.props;

    dispatch(userActions.login(googleUser.getBasicProfile().getId()));
  };

  onFailure = (error) => {
    console.log(error);
  };

  setSignItState(signedIn) {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      signedIn,
    });
  }

  logout = () => {
    const { dispatch } = this.props;

    window.gapi.auth2.getAuthInstance().signOut().then(() => {
      dispatch(userActions.login(null));
    });
  };

  renderSignInButton() {
    window.gapi.signin2.render('my-signin2', {
      onsuccess: this.onSuccess,
      onfailure: this.onFailure,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.state.signedIn
          && (
          <Button
            className={classes.centered}
            onClick={this.logout}
            color="secondary"
          >
            LOGOUT
          </Button>
          )}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.state.signedIn
          || (
          <div className={classes.centered}>
            <div id="my-signin2" />
          </div>
          )}
      </div>
    );
  }
}

const mappingStateToProps = (state) => ({
  user: state.user,
});

export default connect(mappingStateToProps)(withStyles(styles)(Login));

import moment from 'moment/moment';
import filter from 'lodash.filter';

export const getQueue = (cards) => {
  const today = moment().format('YYYYMMDD');

  return filter(cards, (card) => (!card.scheduled || card.scheduled <= today || card.review));
};

export { getQueue as default };

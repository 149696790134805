import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import margin from '../styles/margin';
import { colActions } from '../../../store/_actions/col.actions';

const styles = (theme) => ({
  form: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  standardMargin: {
    margin: margin(theme),
  },
});

class ColAdd extends Component {
    state = {
      name: '',
      redirect: false,
    }

    handleClick = () => {
      this.props.dispatch(
        colActions.add(this.state.name),
      );
      this.setState({
        redirect: true,
      });
    }

    render() {
      const { name, redirect } = this.state;
      const { wordsDisabled } = this.props;

      if (redirect) {
        return <Redirect to="/learn" />;
      }

      return (
        <div className={this.props.classes.standardMargin}>
          <TextField
            id="standard-name"
            label="Name"
            value={name}
            onChange={(e) => this.setState({
              name: e.target.value,
            })}
            margin="normal"
          />

          <Button
            className={this.props.classes.standardMargin}
            variant="contained"
            color="primary"
            disabled={!name || wordsDisabled.includes(name)}
            onClick={this.handleClick}
          >
                add collection
          </Button>
        </div>
      );
    }
}

const mapStateToProps = (state) => ({ wordsDisabled: Object.keys(state) });

export default connect(mapStateToProps)(withStyles(styles)(ColAdd));

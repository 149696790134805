const cardConstants = {
  CARD_ADD: 'CARD_ADD',
  CARD_EDIT: 'CARD_EDIT',
  CARD_SHED: 'CARD_SHED',
  CARD_SET_REVIEWED: 'CARD_SET_REVIEWED',
  SHED_CARDS_FOR_TODAY: 'SHED_CARDS_FOR_TODAY',
  CARD_DEL: 'CARD_DEL',
  STORE_SET: 'STORE_SET',
  CARD_EDIT_STAGE_FRONT: 'CARD_EDIT_STAGE_FRONT',
  CARD_EDIT_STAGE_BACK: 'CARD_EDIT_STAGE_BACK',
  CARD_RESULT_BAD: -1,
  CARD_RESULT_ZERO: 0,
  CARD_RESULT_GOOD: 1,
  CARD_RESET: 'CARD_RESET',
  CARD_RESCHEDULE: 'CARD_RESCHEDULE',
};

export { cardConstants as default, cardConstants };

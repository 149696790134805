import React, { Component } from 'react';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import config from '../../../../config';

class Confirm extends Component {
  handleClose = upOrDown => () => {
    this.props.onClose(upOrDown);
  };

  render() {
    const { ltz, rtz } = this.props;

    return (
      <Dialog
        maxWidth="xs"
        {...this.props}
      >
        <DialogContent>
          <Typography component="div">
            Remote & local databank are different.
            <ul>
              <li>Local: <div>{moment(ltz, 'x').format(config.DATE_FORMAT)}</div></li>
              <li>Cloud: <div>{moment(rtz, 'x').format(config.DATE_FORMAT)}</div></li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this.handleClose('up')}
          >
            Upload
          </Button>
          <Button
            color="primary"
            onClick={this.handleClose('down')}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Confirm;

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';

class ConfirmationDialog extends React.Component {
  handleClose = confirmed => () => {
    this.props.onClose(confirmed);
  };

  render() {
    return (
      <Dialog
        maxWidth="xs"
        {...this.props}
      >
        <DialogContent>
          <Typography>
            Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this.handleClose(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.handleClose(true)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;

import { createStore as reduxCreateStore } from 'redux';
import rootReducer from './_reducers';
import { Persistent, Version } from '../controllers';

/* eslint-disable no-underscore-dangle */

const fromPersistance = Persistent.getStore() || {
  cards: [],
  user: null,
};

let fromPersistanceVersion = Persistent.read(Persistent.constant.VERSION_ITEM_KEY) || '0';
fromPersistanceVersion = parseInt(fromPersistanceVersion, 10);

const createStore = () => reduxCreateStore(
  rootReducer,
  Version.migrateFromVersion(fromPersistanceVersion, fromPersistance),
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default createStore;

const constant = {
  LOCAL_STORAGE_ITEM_KEY: 'state',
  LOCAL_TZ_ITEM_KEY: 'ltz',
  SYNC_TZ_ITEM_KEY: 'stz',
  VERSION_ITEM_KEY: 'version',
};

const Persistent = {

  constant,

  getStore: () => {
    const fromStorage = localStorage.getItem(constant.LOCAL_STORAGE_ITEM_KEY);
    return fromStorage ? JSON.parse(fromStorage) : null;
  },

  putStore: (state) => {
    localStorage.setItem(constant.LOCAL_STORAGE_ITEM_KEY, JSON.stringify(state));
  },

  read: (key) => localStorage.getItem(key),

  write: (key, value) => {
    localStorage.setItem(key, value);
  },

  removeItem: (key) => {
    localStorage.removeItem(key);
  },
};

export { Persistent as default, Persistent };

import {
  Badge, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import React, { Component } from 'react';
import LearnIcon from '@material-ui/icons/School';
import AddIcon from '@material-ui/icons/NoteAdd';
import ListIcon from '@material-ui/icons/ViewList';
import LoginIcon from '@material-ui/icons/ExitToApp';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import FolderIcon from '@material-ui/icons/Folder';
import FolderAddIcon from '@material-ui/icons/CreateNewFolder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getQueue } from '../store/_selectors';
import { pageConstants } from '../store/_constants';
import { colActions } from '../store/_actions';

class MyListItem extends Component {
    state = {
      redirect: false,
    }

    onClick = () => {
      this.setState({
        redirect: true,
      }, () => {
        if (this.props.onClick) {
          this.props.onClick();
        }
      });
    };

    render() {
      const { redirect } = this.state;

      if (redirect) {
        return <Redirect to={this.props.to} />;
      }

      return (
        <>
          <ListItem
            button
            onClick={this.onClick}
          >
            <ListItemIcon>
              <this.props.icon />
            </ListItemIcon>
            {typeof this.props.badgeContent === 'undefined' && <ListItemText primary={this.props.primary} />}

            {typeof this.props.badgeContent !== 'undefined'
                && (
                <Badge
                  badgeContent={this.props.badgeContent}
                  color="primary"
                  max={999}
                >
                  <ListItemText primary={this.props.primary} />
                </Badge>
                )}
          </ListItem>
          {this.props.children}
        </>
      );
    }
}

const Menu = (props) => (
  <div
    tabIndex={0}
    role="button"
    onClick={props.handleClose}
    onKeyDown={props.handleClose}
  >
    <List>
      <MyListItem
        badgeContent={props.queueCount}
        primary="Learn"
        icon={LearnIcon}
        to={pageConstants.PAGE_LEARN}
      />
      <MyListItem
        primary="Add card"
        icon={AddIcon}
        to={pageConstants.PAGE_ADD}
      />
      <MyListItem
        badgeContent={props.cardsCount}
        primary="List cards"
        icon={ListIcon}
        to={pageConstants.PAGE_LIST}
      />
      <MyListItem
        primary="Collection"
        icon={FolderIcon}
        to={pageConstants.PAGE_LIST}
      >
        <List component="div" disablePadding>
          <div style={{
            paddingLeft: '2em',
          }}
          >
            <MyListItem
              primary="add new"
              icon={FolderAddIcon}
              to={pageConstants.PAGE_COL_ADD}
            />
            {props.anotherCols.map(({name: colName, queueCount}) => (
              <MyListItem
                key={colName}
                badgeContent={queueCount}
                primary={colName}
                icon={FolderOpenIcon}
                onClick={() => {
                  props.dispatch(colActions.set(colName));
                }}
                to={pageConstants.PAGE_LIST}
              />
            ))}
          </div>
        </List>
      </MyListItem>
      <MyListItem
        primary={props.user ? 'Logout' : 'Login'}
        icon={props.user ? LogoutIcon : LoginIcon}
        to={pageConstants.PAGE_LOGIN}
      />
    </List>
  </div>
);

const mapping = (state) => (
  {
    queueCount: getQueue(state[state.curCollection].cards).length,
    cardsCount: Object.keys(state[state.curCollection].cards).length,
    user: state.user,
    anotherCols: Object.keys(state).filter((p) => !['curCollection', 'user', state.curCollection].includes(p)).map((n) => ({
      name: n,
      queueCount: getQueue(state[n].cards).length,
    })),
  }
);

export default connect(mapping)(Menu);

import { userConstants } from '../_constants';

function login(user) {
  return {
    type: userConstants.USER_LOGIN,
    user,
  };
}

const userActions = {
  login,
};

export { userActions as default, userActions };

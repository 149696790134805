import React from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import { IconButton, withStyles } from '@material-ui/core';

const styles = {
  rotate: {
    animation: 'spin 4s linear infinite',
  },

  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(-360deg)' },
  },
};

function Sync({ rotate, classes, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      color="inherit"
    >
      <SyncIcon className={rotate ? classes.rotate : ''} />
    </IconButton>
  );
}

export default withStyles(styles)(Sync);

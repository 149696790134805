import { pageConstants } from '../_constants';

export const pageActions = {
  set
};

function set(page) {
  return {
    type: pageConstants.PAGE_SET,
    page
  };
}

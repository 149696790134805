import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List/List';
import CardEditForm from './CardEditForm';
import { pageConstants } from '../../store/_constants';
import Login from './Login';
import LearnContainer from './LearnContainer/LearnContainer';
import ColAdd from "./ColAdd/ColAdd";

const App = () => (
  <Switch>
    <Route exact path="/" component={LearnContainer} />
    <Route path={pageConstants.PAGE_LEARN} component={LearnContainer} />
    <Route path={pageConstants.PAGE_ADD} component={CardEditForm} />
    <Route path={pageConstants.PAGE_LIST} component={List} />
    <Route path={pageConstants.PAGE_LOGIN} component={Login} />
    <Route path={pageConstants.PAGE_COL_ADD} component={ColAdd} />
  </Switch>
);

export default App;

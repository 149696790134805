import createStore from './createStore';
import { Persistent, Version } from '../controllers';

const store = createStore();

store.subscribe(() => {
  Persistent.putStore(store.getState());
  Persistent.write(Persistent.constant.LOCAL_TZ_ITEM_KEY, Date.now());
  Persistent.write(Persistent.constant.VERSION_ITEM_KEY, Version.constant.CURRENT_VERSION);
});

export default store;

import moment from 'moment';
import shortId from 'shortid';
import {
  cardConstants, pageConstants, userConstants, colConstants,
} from '../_constants';
import { Persistent } from '../../controllers';

const DEFAULT_EASE = 2;

function randnBm() {
  const u = 1 - Math.random(); // Subtraction to flip [0, 1) to (0, 1].
  const v = 1 - Math.random();
  return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
}

function randnCorrect(nmb) {
  const newNmb = Math.round(nmb + randnBm());
  return newNmb > 1 ? newNmb : 1;
}

function reducer(state = {}, action) {
  if (action.type === cardConstants.CARD_ADD) {
    const forCard = {
      id: shortId.generate(),
      front: action.front,
      back: action.back,
      ease: DEFAULT_EASE,
      addedOn: moment().format('YYYYMMDD'),
      repetitions: [],
    };

    const revCard = {
      ...forCard,
      id: shortId.generate(),
      front: action.back,
      back: action.front,
      repetitions: [],
    };

    const cards = {
      ...state[state.curCollection].cards,
      [forCard.id]: forCard,
      [revCard.id]: revCard,
    };

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.STORE_SET) {
    return {
      ...action.store,
    };
  }

  if (action.type === cardConstants.CARD_RESET) {
    const cards = {};

    Object.keys(state[state.curCollection].cards).forEach((id) => {
      const card = {
        ...state[state.curCollection].cards[id],
      };

      delete card.review;
      delete card.scheduled;
      card.repetitions = [];
      card.ease = DEFAULT_EASE;

      cards[id] = card;
    });

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.SHED_CARDS_FOR_TODAY) {
    const { cards } = state[state.curCollection];
    const allNotScheduled = Object.values(cards).filter((c) => !c.scheduled);
    const { cardsCount } = action;
    allNotScheduled
      .sort(() => 0.5 - Math.random())
      .slice(0, cardsCount)
      .forEach((c) => {
        cards[c.id] = {
          ...c,
          scheduled: moment().format('YYYYMMDD'),
        };
      });

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.CARD_RESCHEDULE) {
    const today = moment().format('YYYYMMDD');
    let earliestDayReschedule = today;
    Object.keys(state[state.curCollection].cards).forEach((id) => {
      const c = state[state.curCollection].cards[id];

      if (c.scheduled && c.scheduled < earliestDayReschedule) {
        earliestDayReschedule = c.scheduled;
      }
    });

    const addDays = moment().diff(moment(earliestDayReschedule), 'days');

    const cards = {};
    Object.keys(state[state.curCollection].cards).forEach((id) => {
      const c = { ...state[state.curCollection].cards[id] };

      if (c.scheduled) {
        c.scheduled = moment(c.scheduled).add(addDays, 'days').format('YYYYMMDD');
      }

      delete c.review;

      cards[id] = c;
    });

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.CARD_DEL) {
    const cards = {
      ...state[state.curCollection].cards,
    };

    delete cards[action.card.id];

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === userConstants.USER_LOGIN) {
    if (!action.user) {
      Persistent.removeItem(Persistent.constant.SYNC_TZ_ITEM_KEY);
    }

    return {
      ...state,
      user: action.user,
    };
  }

  if (action.type === colConstants.COL_ADD) {
    return {
      ...state,
      [action.name]: {
        cards: [],
      },
      curCollection: action.name,
    };
  }

  if (action.type === colConstants.COL_SET) {
    return {
      ...state,
      curCollection: action.name,
    };
  }

  if (action.type === pageConstants.PAGE_SET) {
    return {
      ...state,
      page: action.page,
    };
  }

  if (action.type === cardConstants.CARD_SET_REVIEWED) {
    const cards = {
      ...state[state.curCollection].cards,
    };

    cards[action.card.id] = {
      ...action.card,
      review: false,
    };

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.CARD_EDIT) {
    const cards = {
      ...state[state.curCollection].cards,
    };

    cards[action.card.id] = {
      ...cards[action.card.id],
      front: action.front,
      back: action.back,
    };

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }

  if (action.type === cardConstants.CARD_SHED) {
    const { result } = action;

    const card = {
      ...action.card,
    };

    const lastInterval = card.repetitions.length
      ? moment().diff(moment(card.repetitions.slice(-1)[0].on), 'days')
      : 1;

    let newInterval = 1;

    switch (result) {
      case cardConstants.CARD_RESULT_GOOD:
        newInterval = randnCorrect(lastInterval * card.ease);
        card.ease += 0.2;
        break;
      case cardConstants.CARD_RESULT_ZERO:
        newInterval = randnCorrect(lastInterval);
        card.review = true;
        break;
      case cardConstants.CARD_RESULT_BAD:
        newInterval = randnCorrect(lastInterval * 0.1);
        card.review = true;
        card.ease -= 0.2;
        if (card.easy < 1.3) {
          card.easy = 1.3;
        }
        break;
      default:
        break;
    }

    card.repetitions = [
      //      ...card.repetitions,
      {
        on: moment().format('YYYYMMDD'),
        result,
      },
    ];

    card.scheduled = moment().add(newInterval, 'days').format('YYYYMMDD');

    const cards = {
      ...state[state.curCollection].cards,
    };

    cards[action.card.id] = card;

    return {
      ...state,
      [state.curCollection]: {
        ...state[state.curCollection],
        cards,
      },
    };
  }
  return state;
}

export { reducer as default, reducer };

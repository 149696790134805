// eslint-disable
import Mood from '@material-ui/icons/Mood';
import SentimentNeutral from '@material-ui/icons/SentimentSatisfiedAlt';
import MoodBad from '@material-ui/icons/MoodBad';
import Edit from '@material-ui/icons/Edit';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import moment from 'moment/moment';
import React from 'react';
import {
  Button, IconButton, Typography, withStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import config from '../../../../config';
import { cardConstants, learnConstants } from '../../../../store/_constants/index';
import CardEditForm from '../../CardEditForm';
import marginFunc from '../../styles/margin';
import MyCard from './MyCard/MyCard';

const styles = (theme) => {
  const margin = marginFunc(theme);

  return {
    '@keyframes flip-in-ver-right': {
      '0%': {
        transform: 'rotate3d(1, 1, 0, -80deg)',
        opacity: 0,
      },
      '100%': {
        transform: 'rotate3d(1, 1, 0, 0deg)',
        opacity: 1,
      },
    },

    flip: {
      animation: '$flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    },

    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },

    headline: {
      margin,
      display: 'flex',
      alignItems: 'center',
    },

    headlineText: {
      fontSize: '75%',
      flex: 1,
    },

    buttonContainer: {
      margin,
      display: 'flex',
      justifyContent: 'space-between',
    },

    buttonNeg: {
      width: '30%',
      color: 'white',
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },

    buttonZero: {
      width: '30%',
      color: 'white',
      backgroundColor: yellow[700],
      '&:hover': {
        backgroundColor: yellow[800],
      },

    },
    buttonPlus: {
      width: '30%',
      color: 'white',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },

    paper: {
      flexGrow: 1,
      margin,
      height: 10,
      overflowY: 'auto',
      position: 'relative',
      textAlign: 'center',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },

    word: {
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '150%',
    },
  };
};

class Learn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: learnConstants.LEARN_STAGE_SHOWFRONT,
      editMode: false,
    };
  }

  handleClick = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.stage === learnConstants.LEARN_STAGE_SHOWFRONT) {
      this.setState({
        stage: learnConstants.LEARN_STAGE_SHOWBACK,
      });
      // eslint-disable-next-line react/destructuring-assignment
    } else if (this.state.stage === learnConstants.LEARN_STAGE_SHOWLAST) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.nextCard();
      this.setState({
        stage: learnConstants.LEARN_STAGE_SHOWFRONT,
      });
    }
  };

  handleClickShed = (result) => () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleShed(result);

    this.setState({
      stage: learnConstants.LEARN_STAGE_SHOWLAST,
    });
  };

  handleClickReview = (result) => () => {
    if (result) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.handleReview(result);
    }

    this.setState({
      stage: learnConstants.LEARN_STAGE_SHOWLAST,
    });
  };

  toggleEditMode = () => {
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
      editMode: !this.state.editMode,
    });
  };

  buttonDisabled() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.stage !== learnConstants.LEARN_STAGE_SHOWBACK;
  }

  render() {
    const { classes, queueCounters: { toRepeat, toReview, toLearn }, card } = this.props;
    const { stage } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.editMode) {
      return (
        <CardEditForm
          card={card}
          onClose={this.toggleEditMode}
        />
      );
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.headline}>
          <Typography
            className={classes.headlineText}
            style={{
              'flex-grow': 0,
              'margin-right': '1rem',
            }}
          >
            to&nbsp;repeat:
            <br />
            to&nbsp;review:
            <br />
            to&nbsp;learn&nbsp;new:
          </Typography>
          <Typography className={classes.headlineText}>
            {toRepeat}
            <br />
            {toReview}
            <br />
            {toLearn}
          </Typography>
          <Typography className={classes.headlineText}>
            scheduled on:
            <br />
            <strong>
              {/* eslint-disable-next-line react/destructuring-assignment */}
              { this.props.currentCardScheduledOn
              // eslint-disable-next-line react/destructuring-assignment
              && moment(this.props.currentCardScheduledOn).format(config.DATE_FORMAT_SHORT)}
              {/* eslint-disable-next-line react/destructuring-assignment */}
              {!this.props.currentCardScheduledOn && 'non yet'}
            </strong>
          </Typography>
          <IconButton
            color="secondary"
            disabled={stage !== learnConstants.LEARN_STAGE_SHOWLAST}
            onClick={this.toggleEditMode}
          >
            <Edit />
          </IconButton>
        </div>
        <div
          onClick={this.handleClick}
          className={classes.form}
          role="presentation"
        >
          <MyCard
            key={`${card.id}-front`}
            classes={classes}
            text={card.front}
          />

          {stage === learnConstants.LEARN_STAGE_SHOWFRONT
            && <div className={classes.paper} />}

          {stage !== learnConstants.LEARN_STAGE_SHOWFRONT
            && (
              <MyCard
                classes={classes}
                text={card.back}
              />
            )}

          {!card.review
            && (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                disabled={this.buttonDisabled()}
                classes={{
                  root: classes.buttonNeg,
                }}
                onClick={this.handleClickShed(cardConstants.CARD_RESULT_BAD)}
              >
                <MoodBad />
              </Button>
              <Button
                variant="contained"
                disabled={this.buttonDisabled()}
                classes={{
                  root: classes.buttonZero,
                }}
                onClick={this.handleClickShed(cardConstants.CARD_RESULT_ZERO)}
              >
                <SentimentNeutral />
              </Button>
              <Button
                variant="contained"
                disabled={this.buttonDisabled()}
                classes={{
                  root: classes.buttonPlus,
                }}
                onClick={this.handleClickShed(cardConstants.CARD_RESULT_GOOD)}
              >
                <Mood />
              </Button>
            </div>
            )}

          {card.review
            && (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                disabled={this.buttonDisabled()}
                classes={{
                  root: classes.buttonNeg,
                }}
                onClick={this.handleClickReview(false)}
              >
                <MoodBad />
              </Button>
              <Button
                variant="contained"
                disabled={this.buttonDisabled()}
                classes={{
                  root: classes.buttonPlus,
                }}
                onClick={this.handleClickReview(true)}
              >
                <Mood />
              </Button>
            </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Learn));

import React from 'react';
import withRoot from '../components/withRoot';
import {Provider} from 'react-redux';
import store from '../store';
import AppContainer from "../components/AppContainer";

class Index extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AppContainer />
      </Provider>
    );
  }
}

export default withRoot(Index);

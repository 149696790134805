import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash.groupby';
import ConfirmationDialog from '../../ConfirmationDialog';
import { cardActions } from '../../../store/_actions';
import CardEditForm from '../CardEditForm';
import Day from './Day';

const CONFIRM_DELETE = 'CONFIRM_DELETE';
const CONFIRM_RESET = 'CONFIRM_RESET';
const CONFIRM_RESCHEDULE = 'CONFIRM_RESCHEDULE';

class CardsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirm: false,
      selectedCard: null,
      editMode: false,
      cardsGrouped: {},
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { cards } = this.props;

    this.groupCards(cards);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.groupCards(nextProps.cards);
  }

  handleClickOnCard = (card) => () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      selectedCard: card,
      editMode: true,
    });
  };

  handleConfirmDialogClose = (confirmed) => {
    const { openConfirm, selectedCard } = this.state;
    const { dispatch } = this.props;

    if (confirmed) {
      switch (openConfirm) {
        case CONFIRM_DELETE:
          dispatch(cardActions.del(selectedCard));
          break;
        case CONFIRM_RESET:
          dispatch(cardActions.reset());
          break;
        case CONFIRM_RESCHEDULE:
          dispatch(cardActions.reschedule());
          break;
        default:
          break;
      }
    }

    this.setState({
      selectedCard: null,
      openConfirm: false,
    });
  };

  handleDeleteClick = (card) => () => {
    this.setState({
      selectedCard: card,
      openConfirm: CONFIRM_DELETE,
    });
  };

  handleEditClose = () => {
    this.setState({
      selectedCard: null,
      editMode: false,
    });
  };

  handleClickReset = () => {
    this.setState({
      openConfirm: CONFIRM_RESET,
    });
  };

  handleClickReschedule = () => {
    this.setState({
      openConfirm: CONFIRM_RESCHEDULE,
    });
  };

  groupCards(cards) {
    this.setState({
      cardsGrouped: groupBy(cards, 'scheduled'),
    });
  }

  render() {
    const {
      cardsGrouped, editMode, selectedCard, openConfirm,
    } = this.state;

    const days = Object.keys(cardsGrouped);

    if (editMode) {
      return (
        <CardEditForm card={selectedCard} onClose={this.handleEditClose} />
      );
    }
    return (
      <div>
        <div>
          <Button
            color="secondary"
            onClick={this.handleClickReset}
          >
            Reset learn progress
          </Button>

          <Button
            color="secondary"
            onClick={this.handleClickReschedule}
          >
            Reschedule progress
          </Button>
        </div>
        {days.map((day) => (
          <Day
            key={day}
            day={day}
            cards={cardsGrouped[day]}
            handleClickOnCard={this.handleClickOnCard}
            handleDeleteClick={this.handleDeleteClick}
          />
        ))}
        <ConfirmationDialog
          onClose={this.handleConfirmDialogClose}
          open={!!openConfirm}
        />
      </div>
    );
  }
}

const mapping = (state) => ({
  cards: state[state.curCollection].cards,
});

export default connect(mapping)(CardsList);
